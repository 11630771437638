import {
    isObjectArray,
} from '@/js/helper.js';
import {
    addEditedRequestValues,
} from '@/js/testStepsHelpers/commonTestFunctions.js';
import testCases from '@/cfg/testCases.json';
import evalParams from '@/cfg/evalParams.json';
import requests from '@/js/requests';
import { cloneDeep } from "lodash-es"

function checkDiameterProtocol(templateName) {
    if (checkOldDiameterProtocol(templateName) || templateName == 'DIAMETER'

    ) {
        return true;
    }
    return false;
}

function checkOldDiameterProtocol(templateName) {
    if (templateName == '3GPP_GX' || templateName == '3GPP_RX_AAR' || templateName == '3GPP_RX_STR' || templateName ==
        '3GPP_S6B_AAR' || templateName == '3GPP_S6B_STR' || templateName == '3GPP_STA_AAR' || templateName ==
        '3GPP_STA_DER' || templateName == '3GPP_STA_STR' || templateName == '3GPP_SWA_DER' || templateName ==
        '3GPP_SWA_STR' || templateName == 'SWM_AAR' || templateName == 'SWM_DER' || templateName == 'SWM_STR' ||
        templateName == 'SWX' || templateName == 'SWM' || templateName == '3GPP_SY' || templateName == 'DCCA'

    ) {
        return true;
    }
    return false;
}

function getPageCat(stepStoredValue) {
    let category = '';

    if (typeof stepStoredValue.category != 'undefined' && stepStoredValue.category != '') {
        category = stepStoredValue.category;
    } else {
        category = 'Protocols';
    }
    return category;
}

/**
 * get the subtype of the step from ether stored one or from the legacy steps mapping
 *
 * @param   {string}  stepType        step type]
 * @param   {object}  stepStoredValue  stored step value
 *
 * @return  {string}                  sub type
 */


function getSubType(stepType, stepStoredValue) {
    let subType = '';

    if (typeof stepStoredValue.subType != 'undefined' && stepStoredValue.subType != '') {
        subType = stepStoredValue.subType;
    } else {
        let mapping = getSubstepMapping();
        if (typeof mapping[stepType] != 'undefined') {
            subType = mapping[stepType];
        }
    }
    return subType;
}

function getSubstepMapping() {
    let mapping = {
        'RADIUS_ACCESS': 'RADIUS',
        'RADIUS_ACCOUNTING': 'RADIUS',
        'DIAMETER': 'DIAMETER',
        'HTTP': 'HTTP',
        'LDAP': 'LDAP',
        'SSH': 'SSH',
        'PING': 'PING',

    };
    return mapping;
}

function getConfiguredValue(stepType, ipcanStep) {
    var configuredValue = {};
    if (typeof stepType != 'undefined') {
        //var configurationTemplate= window.templates[stepType];
        //console.log(testCases, stepType);
        var configurationTemplate = JSON.parse(JSON.stringify(testCases[stepType]));
        if (checkSinglePacket(stepType)) {
            // single packet
            configuredValue = configurationTemplate;

        } else {
            configuredValue = configurationTemplate[ipcanStep];
        }
    }
    return configuredValue;
}

function checkSinglePacket(stepType) {
    var configurationTemplate = testCases[stepType];
    if (typeof configurationTemplate != 'undefined' && typeof configurationTemplate['packetType'] != 'undefined' &&
        configurationTemplate['packetType'] == 'singlePacket') {
        // single packet
        return true;

    } else {
        return false;
    }
}

function getStepValue($testCasesGroup, stepId, stepType, ipcanStep) {
    var stepValue = {};
    if (typeof $testCasesGroup.data('templateValue') != 'undefined' &&
        typeof $testCasesGroup.data('templateValue')[stepId] != 'undefined' &&
        typeof $testCasesGroup.data('templateValue')[stepId][stepType] != 'undefined') {
        stepValue = $testCasesGroup.data('templateValue')[stepId][stepType];
        // console.log($testCasesGroup.data( "templateValue"),stepId,stepType);

        var steps = [];
        //console.log(stepValue);
        if (typeof stepValue.ipcanSteps !== 'undefined') {

            steps = stepValue.ipcanSteps;
        } else if (ipcanStep == 'DCCA') {
            steps = stepValue['dcca-sessionSteps'];
        }
        let ipcanStepIndex = getIpcanIndex(steps, ipcanStep);
        if (ipcanStepIndex > -1) {
            stepValue = steps[ipcanStepIndex][1];
            //////////console.log(stepValue);
        }
    }
    return stepValue;
}

function getIpcanIndex(ipcanSteps, ipcanStep) {
    var ipcanStepIndex = -1;
    if (typeof ipcanSteps != 'undefined' && ipcanSteps.length > 0) {

        for (let [index, ipcanStepValue] of Object.entries(ipcanSteps)) {

            if (ipcanStepValue[0] == ipcanStep) {
                ipcanStepIndex = index;
                // return true;
                break;
            }
        }
    }
    return ipcanStepIndex;
}

function addEditedRadiusDiameterValues(originalTemplate, ipcanStep, stepStoredValue, stepType) {
    //console.log(originalTemplate);
    // search for the required value with the correct source
    // suppress the target value
    // add testcase attributest
    // if (stepType == "HTTP") {
    //   // suppress source values and add test case ones
    // } else {
    //if (fieldsObject.data( "templateValue")[stepId] && fieldsObject.data( "templateValue")[stepId][stepType]){
    console.log(originalTemplate);
    let template;
    try {
        template = JSON.parse(JSON.stringify(originalTemplate));
    } catch (error) {
        template = cloneDeep(originalTemplate);
    }
    if (typeof stepStoredValue != 'undefined') {

        if (isObjectArray(stepStoredValue)) {
            if (checkDiameterProtocol(ipcanStep)) {
                if (isObjectArray(stepStoredValue.req)) {
                    for (let [reqTypeIndex, reqTypeValue] of Object.entries(stepStoredValue.req)) {
                        // $.each(stepStoredValue.req, function (reqTypeIndex, reqTypeValue) {
                        template.req[reqTypeIndex] =
                            addEditedRequestValues(stepType, template.req[reqTypeIndex], reqTypeValue, 0);
                        //});
                    }
                }
            } else {
                if (isObjectArray(stepStoredValue.req)) {
                    // $.each(stepStoredValue.req, function (reqIndex, reqValue) {
                    for (let [reqIndex, reqValue] of Object.entries(stepStoredValue.req)) {
                        template.req[reqIndex] = addEditedRequestValues(stepType, template.req[reqIndex], reqValue, 0);
                    }
                    // });
                }
            }

            if (template.res) {
                if (ipcanStep == 'DIAMETER') {
                    // suppress all templates response by default
                    console.log(stepStoredValue.res);
                    if (stepStoredValue.res && stepStoredValue.res.eval) {
                        /* if (typeof stepStoredValue.res.eval != "undefined" && stepStoredValue.res.eval != null) {
                           for (let [resArrayIndex, resArrayValue] of Object.entries(stepStoredValue.res.eval)) {
                             // $.each(stepStoredValue.res.eval,function(resArrayIndex,resArrayValue){
                             if (resArrayIndex != "code") {

                               if (typeof template.res.eval[resArrayIndex] == "undefined") {
                                 // if the templtes are not defined then the stored attributes must come from test case and can be deleteabel
                                 let pushedEval = [];
                                 let copiedObject = [];
                                 // $.extend(true,copiedObject,resArrayValue);
                                 copiedObject = [...resArrayValue];
                                 copiedObject.forEach(evalValue => {
                                   //$.each(copiedObject,function(evalIndex,evalValue){
                                   evalValue.source = "t_testcases";
                                   evalValue.mandatory = 1;
                                   pushedEval.push(evalValue);
                                 });
                                 template.res.eval[resArrayIndex] = pushedEval;
                               } else {
                                 template.res.eval[resArrayIndex] = replaceAndAddValues(template.res.eval[resArrayIndex], resArrayValue);
                               }
                             }
                             // });
                           }
                         }*/
                        template.res = stepStoredValue.res;
                    }
                } else {
                    // suppress all templates response by default
                    if (stepStoredValue.res && typeof stepStoredValue.res.eval != 'undefined' &&
                        stepStoredValue.res.eval != null) {
                        if (template.res.eval) {
                            for (let [index, value] of Object.entries(template.res.eval)) {
                                // $.each(template.res.eval, function (index, value) {
                                if (typeof stepStoredValue.res.eval[index] != 'undefined') {
                                    for (let [innerIndex, innerValue] of Object.entries(value)) {
                                        //$.each(value, function (innerIndex, innerValue) {
                                        if ((isObjectArray(innerValue))) {
                                            for (let [attrIndex] of Object.entries(innerValue)) {
                                                // $.each(innerValue, function (attrIndex, attrValue) {
                                                if (template.res.eval[index][innerIndex][attrIndex].fix != 1) {
                                                    template.res.eval[index][innerIndex][attrIndex].fix = 2;
                                                }
                                                // });
                                            }
                                        }
                                        //});
                                    }
                                }
                            }
                            // });
                        }
                    }
                    if (stepStoredValue.res) {
                        if (typeof stepStoredValue.res.eval != 'undefined' && stepStoredValue.res.eval != null) {
                            // $.each(stepStoredValue.res.eval, function (resIndex, resValue) {
                            for (let [resIndex, resValue] of Object.entries(stepStoredValue.res.eval)) {
                                var templateResponseIndex = resIndex;
                                if (typeof template.res.eval[templateResponseIndex] == 'undefined') {
                                    template.res.eval[templateResponseIndex] = resValue;
                                } else {
                                    if (typeof resValue.errAction != 'undefined') {
                                        template.res.eval[templateResponseIndex] = resValue;
                                    } else {
                                        for (let [resArrayIndex, resArrayValue] of Object.entries(resValue)) {
                                            //$.each(resValue, function (resArrayIndex, resArrayValue) {
                                            if (resArrayIndex != 'code') {
                                                if (typeof template.res.eval[templateResponseIndex][resArrayIndex] ==
                                                    'undefined') {
                                                    // if the templtes are not defined then the stored attributes must come from test case and can be deleteabel
                                                    let pushedEval = [];
                                                    let copiedObject = [];
                                                    copiedObject = JSON.parse(JSON.stringify(resArrayValue));
                                                    // $.extend(true, copiedObject, resArrayValue);
                                                    for (let evalValue of Object.values(copiedObject)) {
                                                        //$.each(copiedObject, function (evalIndex, evalValue) {
                                                        evalValue.source = 't_testcases';
                                                        evalValue.mandatory = 1;
                                                        pushedEval.push(evalValue);
                                                        //});
                                                    }
                                                    template.res.eval[templateResponseIndex][resArrayIndex] =
                                                        pushedEval;
                                                } else {
                                                    template.res.eval[templateResponseIndex][resArrayIndex] =
                                                        replaceAndAddValues(
                                                            template.res.eval[templateResponseIndex][resArrayIndex],
                                                            resArrayValue);
                                                }

                                            }
                                        }
                                        //});
                                    }
                                }
                            }
                            // });
                        }
                        if (typeof stepStoredValue.res.expectedResponse != 'undefined') {
                            template.res.expectedResponse = stepStoredValue.res.expectedResponse;
                        }
                        if (typeof stepStoredValue.res.onTimeout != 'undefined') {
                            template.res.onTimeout = stepStoredValue.res.onTimeout;
                        }
                    }
                }
            }
        }
    }
    //}
    return template;
}

function replaceAndAddValues(sourceArray, addValuesArray) {
    var returnArray = [];
    //console.log(addValuesArray)
    returnArray = JSON.parse(JSON.stringify(sourceArray));
    // $.extend(true,returnArray,sourceArray);
    // add the values which are not found to the source array
    addValuesArray.forEach((singleValue, testIndex) => {

        // $.each(addValuesArray,function(singleIndex,singleValue){
        var foundIndex = -1;
        var searchArray = {};
        //$.extend(searchArray,singleValue);
        //console.log(singleValue);
        searchArray = JSON.parse(JSON.stringify(singleValue));
        delete searchArray.fix;
        delete searchArray.source;
        delete searchArray.color;
        for (let [resInnerIndex, resInnervalue] of Object.entries(sourceArray)) {
            //$.each(sourceArray ,function(resInnerIndex,resInnervalue){
            var found = false;
            for (let [searchIndex, searchValue] of Object.entries(searchArray)) {
                // $.each(searchArray,function(searchIndex,searchValue){
                if (typeof resInnervalue[searchIndex] != 'undefined' && (resInnervalue[searchIndex] == searchValue)) {
                    //same value move on
                    found = true;
                } else {
                    found = false;
                    break;
                    // return false;
                }
            }
            // });

            if (found) {
                // put original
                foundIndex = resInnerIndex;
            }
        }
        // });
        if (foundIndex > -1) {
            // put original
            returnArray[foundIndex] = singleValue;
            let storedTemplateValue = returnArray[foundIndex];
            returnArray.splice(foundIndex, 1);
            returnArray.splice(testIndex, 0, storedTemplateValue);
        } else {
            returnArray.push(singleValue);
        }
    });

    // });
    return returnArray;
}

function addAttributes(target, source, color) {
    if (!target || target.length == 0) {
        target = {};
    }
    for (let [reqAttrIndex, reqAttrValue] of Object.entries(source)) {
        // $.each(source, function (reqAttrIndex, reqAttrValue) {
        if (Array.isArray(reqAttrValue)) {
            if (!target[reqAttrIndex]) {
                target[reqAttrIndex] = [];
            }
            reqAttrValue.forEach(function (value) {
                //$.each(reqAttrValue, function (index, value) {
                value.color = color;
                ////////////console.log(target,reqAttrIndex, target[reqAttrIndex]);
                //   if (!isObjectArray(value.content)) {
                target[reqAttrIndex].push(value);
                // } else {
                //   // ////////console.log(target[reqAttrIndex].content,value.content);
                //   target[reqAttrIndex].push(addAttributes(target[reqAttrIndex], value.content, color));
                //   // var content=addAttributes(target[reqAttrIndex].content,value.content,color);
                //   ////////console.log(value.content,"target",target[reqAttrIndex],reqAttrIndex);
                // }

            });

        }
        // });
    }
    // ////////console.log(target);
    return target;
}

// function addAttributes(target,source,color){
//   if (!target || target.length==0) {
//     target={};
//   }
//   $.each(source,function(reqAttrIndex,reqAttrValue){
//     if($.isArray(reqAttrValue)){
//       if(!target[reqAttrIndex]) {
//         target[reqAttrIndex]=[];
//       }
//       $.each(reqAttrValue,function(index,value){
//         value.color=color;
//         ////////////console.log(target,reqAttrIndex, target[reqAttrIndex]);
//         //  if (!isObjectArray(value.content))
//         {
//           target[reqAttrIndex].push(value);
//         }
//         /*   else {
//       // ////////console.log(target[reqAttrIndex].content,value.content);
//       // target[reqAttrIndex].push(addAttributes(target[reqAttrIndex],value.content,color));
//       // var content=addAttributes(target[reqAttrIndex].content,value.content,color);
//         ////////console.log(value.content,"target",target[reqAttrIndex],reqAttrIndex);
//         }*/

//       });

//     }
//   });
//   // ////////console.log(target);
//   return target;
// }

function getDynamicAttributes(content) {
    var inputType = 'static';
    if (typeof content == 'string' && content.indexOf('EVAL:') > -1) {
        inputType = 'dynamic';
        // $dynamicValue.attr("checked",'checked');
    } else {
        // $staticValue.attr("checked",'checked');
    }
    return inputType;
}

function initializeDynamicParams(additionalEvalParams) {
    let dynamicPars = {};
    let evalAliases = {};
    for (let [parameter, dynamicAttrs] of Object.entries(evalParams['parameters'])) {
        //$.each(evalParams['parameters'], function (parameter, dynamicAttrs) {
        dynamicPars[parameter] = [];
        for (let [index, value] of Object.entries(evalParams['Alias'])) {
            // $.each(evalParams["Alias"], function (index, value) {

            if (dynamicAttrs.indexOf(index) > -1) {
                dynamicPars[parameter].push([value, index]);
                if (typeof evalAliases[parameter] == 'undefined') {
                    evalAliases[parameter] = {};
                }
                evalAliases[parameter][value] = index;
            }
            //});
        }
        //console.log(window.evalAliases);
    }
    // });
    for (let [parameter, value] of Object.entries(additionalEvalParams)) {
        // $.each(additionalEvalParams, function (parameter, value) {
        value.forEach(function (pairValue) {
            //$.each(value, function ( pairValue) {
            dynamicPars[parameter].push(pairValue);
            if (typeof evalAliases[parameter] == 'undefined') {
                evalAliases[parameter] = {};
            }
            evalAliases[parameter][pairValue[0]] = pairValue[1];
        });
    }
    return {
        dynamicPars: dynamicPars,
        evalAliases: evalAliases,
    };
    //});
    // window.dynamicPars = $.extend(true, window.dynamicPars, window.additionalEvalParams);
}

function getIndexedTemplate(index, ipcanStep, templatesData) {
    let value = {};
    let objectTemplate = {};
    if (typeof templatesData != 'undefined' && typeof templatesData[index] != 'undefined') {
        //console.log(templatesData[index], ipcanStep);
        value = JSON.parse(templatesData[index]);
    }
    if (value && value[ipcanStep]) {
        objectTemplate = value[ipcanStep];
    }
    //console.log(objectTemplate);
    return objectTemplate;
}

function buildDiameterRequestTemplate(template, ipcanStep, templatesData, config) {

    let objectTemplate = getIndexedTemplate(config.type, ipcanStep, templatesData);
    let color = config['color'];

    if (typeof objectTemplate['req'] != 'undefined') {
        template['req'] = addAttributes(template['req'], objectTemplate['req'], color);
    }

    return template;
}

function clearTest(index, value, currentTable, caller) {
    requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=testsExecuteApi',
        data: {
            function: 'clearTest',
            n_id: index,
            n_id_key: value.n_id_key,
        },
    })
        .then(function (response) {
            let result = response.data.result.json.result;
            if (result == 'true') {
                clearRowInTable(index, currentTable, caller);
            }
        })
        .catch(function () {
            //handle error
        });
}

function validateOTP(value, caller) {
    let options = {
        function: 'validateOTP',
        in: value,
        dataType: 'json',
        requestType: 'ajax',
    };
    if (value.length == 6) {
        requests.frameworkAxiosRequest({
            method: 'POST',
            url: 'serve.php?f=administration&f2=userSelfService&f3=myAccount',
            data: options,
        })
            .then(function (response) {
                if (response.data.result.json.result == false) {
                    caller.$notify({
                        group: 'foo',
                        text: 'Two-factor code verification failed. Please try again.',
                        duration: 2000,
                        type: 'warning',
                        speed: 600,
                    });
                } else {
                    caller.$notify({
                        group: 'foo',
                        text: 'Two-factor authentication successfully Configured!',
                        duration: 2000,
                        type: 'success',
                        speed: 600,
                    });

                    caller.$store.commit('showRecoveryList', true);
                    caller.$store.commit('closeDialog', caller.result.dialogRef);
                }
            })
            .catch(function () {
                //handle error
            });
    }
}

function clearRowInTable(id, currentTable, caller) {

    let index = -1;
    currentTable.data.forEach((element, i) => {
        if (element.n_id == id) {
            index = i;
            return;
        }
    });
    currentTable.data.splice(index, 1);
    caller.$store.commit('tableChanged', currentTable);
}

function updateTestVariables(id, object, testVariables, caller, addedIds) {
    addedIds.push(id);
    let commands = JSON.parse(object[id]);
    //console.log("command is template", object[id]);
    commands.forEach((commandLine) => {

        if (commandLine.command == 'template' && !addedIds.includes(commandLine.id)) {
            updateTestVariables(commandLine.id, object, testVariables, caller, addedIds);
        } else {
            let variable = '';
            if (typeof commandLine.target != 'undefined') {
                variable = commandLine.target;
                addSingleVariable(variable, testVariables, caller);
            }
            if (typeof commandLine.url != 'undefined') {
                variable = commandLine.url;
                addSingleVariable(variable, testVariables, caller);
            }

            if (typeof commandLine.input != 'undefined') {
                variable = commandLine.input;
                addSingleVariable(variable, testVariables, caller);
            }

            if (typeof commandLine.eval != 'undefined') {

                commandLine.eval.forEach((evalLine) => {
                    variable = evalLine.content;
                    addSingleVariable(variable, testVariables, caller);

                });

            }
        }

    });

}

function addSingleVariable(variable, testVariables, caller) {
    //console.log(variable, testVariables, caller)
    //let regex = /^\$\{(.*)\}$/;
    let regex = /\$\{(.*?)\}/g;
    let matchArray = [...variable.matchAll(regex)];
    // console.log(variable);
    matchArray.forEach((match) => {
        //console.log(match);
        if (variable != '' && match != null) {
            let found = false;
            Object.values(testVariables).some((variables) => {
                variables.some((variable) => {
                    if (variable.name == match[1]) {
                        //variable allready configured
                        found = true;
                        return;
                    }
                });
            });
            if (!found) {
                if (typeof testVariables != 'object' || Array.isArray(testVariables)) {

                    testVariables = {};
                }
                if (typeof testVariables.testlocal == 'undefined') {

                    caller.$set(testVariables, 'testlocal', []);
                }
                testVariables.testlocal.push({
                    name: match[1],
                    value: '',
                    'flags': {'start': 0},
                });
                caller.$set(caller.result.valueLine, 'c_testVariables', JSON.stringify(testVariables));
                caller.$store.commit('dialogResultChanged', caller.result);
            }
        }
    });
}

function getFilteredTestCaseTypes(subscriberType, storedTestCaseTypes, noSubscriberAllowedTypes) {
    const testCaseTypes = JSON.parse(JSON.stringify(storedTestCaseTypes));

    if (subscriberType == 0 || subscriberType == 'explorer') {
        for (let category in testCaseTypes) {
            if (subscriberType != 'explorer' || (category != 'Explorer' && category!='Custom_Scripts' && category!='Explorer_Cellular' )) {
                for (let index in testCaseTypes[category]) {
                    if (noSubscriberAllowedTypes.indexOf(testCaseTypes[category][index]['value']) == -1) {
                        delete testCaseTypes[category][index];
                    }
                }
                testCaseTypes[category] = testCaseTypes[category].filter(function (el) {
                    return el != null && el != '';
                });
            }
        }
    }
    if (subscriberType != 'explorer') {
        delete testCaseTypes['Explorer'];
        delete testCaseTypes['Custom_Scripts'];
        delete testCaseTypes['Explorer_Cellular'];
    }
    return testCaseTypes;
}

function findLive() {
    let currentURL = window.location.href;
    let result = false;
    //fixing issue when  email has @acctopus.com
    if (currentURL.indexOf('degust.acctopus.com') > 0) {
        result = true;
    }
    return result;
}

export {
    findLive,
    getFilteredTestCaseTypes,
    updateTestVariables,
    clearTest,
    buildDiameterRequestTemplate,
    getIndexedTemplate,
    getSubstepMapping,
    checkDiameterProtocol,
    getConfiguredValue,
    getStepValue,
    addEditedRadiusDiameterValues,
    addAttributes,
    getDynamicAttributes,
    initializeDynamicParams,
    getPageCat,
    getSubType,
    validateOTP,
};
